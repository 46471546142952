












































import { AxiosResponse } from 'axios'
import { fetchLive } from '@/inc/utils'
import {
  defineComponent,
  onBeforeMount,
  ref,
  computed,
} from '@vue/composition-api'
import * as sso from '@/composables/sso'

export default defineComponent({
  name: 'my-choice',
  components: {},
  props: {
    user: {
      required: false,
      type: Object as () => Record<string, string>,
    },
    token: {
      required: true,
      type: String,
    },
  },

  setup(props, ctx) {
    const { $i18n, $router } = ctx.root
    const i18n = ref<Record<string, any>>({})
    const htmlContent = computed(() =>
      i18n.value.choice?.htmltext?.replace(
        /{#??email}/,
        `<b>${props.user?.bpEmail}</b>`
      )
    )

    const onLink = () => {
      sso.authorize(`/ppp/link/${props.token}/`)
    }

    onBeforeMount(async () => {
      if (!props.user) {
        $router.push({
          name: 'MyPpp',
          // params: { lang: $i18n.locale },
        })
      }

      const { route: createRoute } = $router.resolve('/create')
      const res: AxiosResponse = await fetchLive(createRoute)

      i18n.value = res.data.i18n || {}
    })

    return {
      i18n,
      htmlContent,
      onLink,
    }
  },
})
