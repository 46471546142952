var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-choice wrapper m-l"},[_c('div',{staticClass:"row"},[(_vm.i18n.choice)?_c('div',{staticClass:"col-xxxs col-m-14"},[(_vm.i18n.choice.title)?_c('h1',{staticClass:"mb-m"},[_vm._v(" "+_vm._s(_vm.i18n.choice.title)+" ")]):_vm._e(),(_vm.i18n.choice.subtitle)?_c('h2',{staticClass:"fatline h3 mb-s"},[_vm._v(" "+_vm._s(_vm.i18n.choice.subtitle)+" ")]):_vm._e(),(_vm.i18n.choice.htmltext)?_c('div',{staticClass:"mb-m",domProps:{"innerHTML":_vm._s(_vm.htmlContent)}}):_vm._e(),_c('ul',{staticClass:"choices"},[_c('li',{staticClass:"choices__item mb-xxs"},[_c('g-action',{attrs:{"content":{
              label: _vm.i18n.choice.goToDashboard,
              icon: 'arrow',
              modifiers: ['rounded', 'dark', 'link', 'no-btn'],
              tag: 'button',
            }},on:{"on-click":_vm.onLink}})],1),_c('li',{staticClass:"choices__item"},[_c('g-action',{attrs:{"content":{
              label: _vm.i18n.choice.goToSignup,
              to: { name: 'MyPppSignup' },
              icon: 'arrow',
              modifiers: ['rounded', 'dark'],
            }}})],1)])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }